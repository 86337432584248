import { ref } from 'vue';
import { createI18n } from 'vue-i18n';

export const locale = ref(localStorage.getItem('language') || 'nl-NL');

export const availableLocales = ['nl-NL', 'fr-FR', 'en-US'];

export const i18n = createI18n({
    legacy: false,
    locale: locale.value,
    fallbackLocale: 'en-US',
    missingWarn: false,
    fallbackWarn: false,
    messages: {}
});

export const setI18nLanguage = async (newLocale: string) => {
    await loadLocaleMessages(newLocale);
    i18n.global.locale.value = newLocale;
    localStorage.setItem('language', newLocale);

    document.querySelector('html')?.setAttribute('lang', newLocale.substring(0, 2));
};

const getTranslations = async (locale: string) => {
    let messages: Record<string, () => Promise<any>> = {};

    switch (locale) {
        case 'nl-NL':
            messages = import.meta.glob('/src/locales/nl-nl/**/*.json');
            break;
        case 'fr-FR':
            messages = import.meta.glob('/src/locales/fr-fr/**/*.json');
            break;
        case 'en-US':
            messages = import.meta.glob('/src/locales/en-us/**/*.json');
            break;
        default:
            messages = {};
    }

    return messages;
};

export const loadLocaleMessages = async (newLocale: string) => {
    const translationPaths = await getTranslations(newLocale);

    let messages: Record<string, any> = {};

    for (const path in translationPaths) {
        try {
            const module = await translationPaths[path]();
            messages = { ...messages, ...module.default };
        } catch (error) {
            console.error(`Error loading translation from ${path}:`, error);
        }
    }

    i18n.global.setLocaleMessage(newLocale, messages);
};

loadLocaleMessages(locale.value);

export default i18n;
