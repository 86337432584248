<script setup lang="ts">
import { computed } from 'vue';
import { RouterView, useRoute } from 'vue-router';

import DefaultLayout from '@/layouts/DefaultLayout.vue';

import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';

import { SwProgressBar, SwLogo } from '@relinks/sw-components';

import { storeToRefs } from 'pinia';
import { useAuthStore } from './stores/auth';
import { useLoadingStore } from './stores/loading';

const { isLoggedIn } = storeToRefs(useAuthStore());
const { appLoaded, loadingProgress, loadingStep } = storeToRefs(useLoadingStore());

const route = useRoute();
const layout = computed(() => route.meta.layout || DefaultLayout);
</script>

<template>
    <div>
        <component :is="layout" v-if="!isLoggedIn || appLoaded || route.meta.authPage">
            <RouterView />
        </component>

        <div v-else class="fixed inset-0 flex justify-center items-center bg-white dark:bg-surface-950">
            <div class="flex flex-col justify-center items-center gap-5 text-center w-full max-w-96 px-5">
                <SwLogo class="w-52" />
                <SwProgressBar class="w-full" :value="loadingProgress" />
                <span v-if="loadingStep">{{ $t(`loading.${loadingStep}`) }}</span>
            </div>
        </div>

        <Toast :pt="{ root: { class: '!w-auto ml-5 max-w-96' } }" />
        <ConfirmDialog />
    </div>
</template>
