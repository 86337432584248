import { useAuthStore } from '@/stores/auth';
import type { NavigationGuardNext, RouteLocation } from 'vue-router';

const authenticationMiddleware = async (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) => {
    const authStore = useAuthStore();

    if (!to.meta.authPage && !authStore.accessToken) {
        next({ name: 'Login' });
    } else {
        next();
    }
};

export default authenticationMiddleware;
