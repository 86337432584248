import { usePermissionsStore } from '@/stores/permissions';
import type { NavigationGuardNext, RouteLocation } from 'vue-router';

const permissionsMiddleware = async (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext) => {
    const permissionsStore = usePermissionsStore();

    if (!to.meta.authPage && to.meta.permission) {
        if (!permissionsStore.checkPermission(String(to.meta.permission))) {
            next({ name: 'forbidden' });
        }
    } else {
        next();
    }
};

export default permissionsMiddleware;
