SwTopbar.vue
<script setup lang="ts">
import { useUserStore } from '@/stores/user';

import { SwIcon, SwButton } from '@relinks/sw-components';

const userStore = useUserStore();
</script>

<template>
    <div class="flex justify-end py-1.5 px-4 border-b bg-white">
        <div class="flex items-center gap-2">
            <div class="flex items-center gap-2 mr-2">
                <span
                    class="flex justify-center items-center rounded-full w-9 h-9 text-white bg-surface-400 dark:bg-surface-600"
                >
                    <SwIcon icon="user" />
                </span>
                <span class="capitalize">{{ userStore.settings.fullName }}</span>
            </div>
            <router-link :to="{ name: 'Logout' }">
                <SwButton severity="warn" :label="$t('terms.logOut')" />
            </router-link>
        </div>
    </div>
</template>
