import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/auth/login',
        name: 'Login',
        component: () => import('@/views/auth/LoginPage.vue'),
        meta: {
            authPage: true,
            breadcrumbs: false
        }
    },
    {
        path: '/auth/logout',
        name: 'Logout',
        component: () => import('@/views/auth/LogOutPage.vue'),
        meta: {
            authPage: true,
            breadcrumbs: false
        }
    },
    {
        path: '/auth/callback',
        name: 'Callback',
        component: () => import('@/views/auth/CallbackPage.vue'),
        meta: {
            authPage: true,
            breadcrumbs: false
        }
    },
    {
        path: '/auth/silent-renew',
        name: 'SilentRenew',
        component: () => import('@/views/auth/SilentRenewPage.vue'),
        meta: {
            authPage: true,
            breadcrumbs: false
        }
    }
];

export default routes;
