import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client/core';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { useAuthStore } from '@/stores/auth';
import { useUserStore } from '@/stores/user';
import * as Sentry from '@sentry/vue';

import { getRuntimeConfig } from '@/helpers/application/getRuntimeConfig';

const httpLink = new BatchHttpLink({
    uri: `${getRuntimeConfig().sawidayApiUrl}/graphql`,
    batchMax: 5,
    batchInterval: 20
});

const dynamicLink = new ApolloLink((operation, forward) => {
    const { defaultSiteId, defaultSiteviewId } = useUserStore();

    if (defaultSiteId && defaultSiteviewId) {
        const uri = `${getRuntimeConfig().sawidayApiUrl}/graphql?site=${defaultSiteId}&siteViewId=${defaultSiteviewId}`;
        operation.setContext({ uri });
    } else {
        Sentry.captureMessage('defaultSiteId or defaultSiteviewId is null.');
    }

    return forward(operation);
});

const authMiddleware = new ApolloLink((operation, forward) => {
    const authStore = useAuthStore();

    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            authorization: `Bearer ${authStore.accessToken}`
        }
    }));

    return forward(operation);
});

const link = from([authMiddleware, dynamicLink, httpLink]);

const cache = new InMemoryCache({
    addTypename: false
});

const sawidayClient = new ApolloClient({
    link,
    cache
});

export default sawidayClient;
