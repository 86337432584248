import { ref, type Ref } from 'vue';
import adminApi from '@/services/admin-api';
import { defineStore, acceptHMRUpdate } from 'pinia';
import * as Sentry from '@sentry/vue';

interface Permissions {
    [key: string]: boolean;
}

interface PermissionsStore {
    loading: Ref<boolean>;
    permissions: Ref<Permissions | {}>;
    fetchPermissions: () => Promise<void>;
    checkPermission: (permission: string) => boolean;
}

export const usePermissionsStore = defineStore<string, PermissionsStore>('permissions', () => {
    const loading = ref(false);
    const permissions = ref<Permissions>({});

    const fetchPermissions = async () => {
        loading.value = true;

        try {
            const { data } = await adminApi.get('/rights');

            if (data.success === true) {
                permissions.value = data.data;
            }
        } catch (error) {
            Sentry.captureException(error);
        } finally {
            loading.value = false;
        }
    };

    const checkPermission = (permission: string): boolean => {
        return permissions.value[permission] ?? false;
    };

    return {
        loading,
        permissions,
        fetchPermissions,
        checkPermission
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePermissionsStore, import.meta.hot));
}
