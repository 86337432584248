import * as graphQLClient from '@/helpers/application/graphQLClient';
import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref } from 'vue';

import * as Sentry from '@sentry/vue';

import DeleteTntScan from '@/services/graphql/deleteTntScan.gql';
import CreateTntScan from '@/services/graphql/createTntScan.gql';
import GetAllTntScans from '@/services/graphql/getAllTntScans.gql';
import { useUserStore } from './user';
import { useToastStore } from './toast';
import i18n from '@/i18n';

interface User {
    email?: string;
    firstName?: string;
    lastName?: string;
}

interface TrackAndTrace {
    orderDelivery: {
        id: number;
    };
}

interface TntScan {
    id: number;
    createdAt?: string;
    barcode: string;
    user: User;
    trackAndTrace: null | TrackAndTrace;
    loading?: boolean;
    deleting?: boolean;
}

interface GetAllTntScansVariables {
    filters: {
        dateFrom?: string | null;
        dateTo?: string | null;
        barcodeContains?: string | null;
        user?: {
            id: number;
        };
    };
    pagination: {
        limit?: number | null;
        sorting?: {
            field: string;
            order: 'ASC' | 'DESC';
        };
        offset?: number | null;
    };
}

export const usePalletScansStore = defineStore('pallet-scans', () => {
    const barcode = ref<string>('');
    const palletScans = ref<TntScan[]>([]);
    const loadingScans = ref(false);

    const fetchPalletScans = async (variables: GetAllTntScansVariables) => {
        loadingScans.value = true;

        try {
            const response = await graphQLClient.query<TntScan[]>(
                GetAllTntScans,
                {
                    filters: variables.filters,
                    paginationInput: variables.pagination
                },
                ['getAllTntScans', 'results']
            );

            if (response.status === 'success' && response.payload) {
                palletScans.value = response.payload;
            }
        } catch (error) {
            Sentry.captureException(error);
        } finally {
            loadingScans.value = false;
        }
    };

    const postPalletScan = async () => {
        const userStore = useUserStore();
        const toastStore = useToastStore();

        if (barcode.value === '') {
            return;
        }

        let barcodeClone = barcode.value;
        if (barcode.value.charAt(0) === '*' && barcode.value.slice(-1) === '$') {
            barcodeClone = barcode.value.substring(1, barcode.value.length - 1);
        }

        // Reset Barcode so the next scan can be entered
        barcode.value = '';

        palletScans.value.unshift({
            id: parseInt(barcodeClone),
            barcode: barcodeClone,
            trackAndTrace: null,
            loading: true,
            user: {
                email: '',
                firstName: '',
                lastName: ''
            }
        });

        if (userStore.settings.userId) {
            try {
                const response = await graphQLClient.mutate<TntScan>(
                    CreateTntScan,
                    {
                        userId: userStore.settings.userId,
                        barcode: barcodeClone
                    },
                    ['createTntScan']
                );

                if (response.status === 'success' && response.payload) {
                    const index = palletScans.value.findIndex((scan) => scan.id === parseInt(barcodeClone));

                    if (palletScans.value[index]) {
                        palletScans.value[index] = response.payload;
                        palletScans.value[index].loading = false;
                    }
                }

                if (response.status === 'error') {
                    palletScans.value = palletScans.value.filter((scan) => scan.id !== parseInt(barcodeClone));

                    toastStore.addToast('error', i18n.global.t('messages.error.title'), response.payload);
                }
            } catch (error) {
                Sentry.captureException(error);
            }
        }

        barcode.value = '';
    };

    const deletePalletScan = async (id: number) => {
        const scan = palletScans.value.find((scan) => scan.id === id);

        if (scan) {
            scan.deleting = true;

            try {
                const response = await graphQLClient.mutate(DeleteTntScan, { id: id });

                if (response.status === 'success') {
                    palletScans.value = palletScans.value.filter((scan) => scan.id !== id);
                } else {
                    scan.deleting = false;
                }
            } catch (error) {
                Sentry.captureException(error);
            }
        }
    };

    return {
        barcode,
        palletScans,
        loadingScans,
        fetchPalletScans,
        postPalletScan,
        deletePalletScan
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePalletScansStore, import.meta.hot));
}
