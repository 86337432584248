/* global runtimeConfig */
import type { RuntimeConfig } from '../../../public/config/config';

export const getRuntimeConfig = () => {
    const config = runtimeConfig as RuntimeConfig;

    const ssoAuthority = config.sso.authority ?? 'https://sso/realms/sawiday';
    const ssoClientId = config.sso.clientId ?? 'pallet-scan';
    const ssoScope = config.sso.scope ?? 'openid email profile';

    const apiBaseUrl = config.api.baseUrl ?? 'https://api';
    const apiVersion = config.api.version ?? '';

    const sawidayApiUrl = `${apiBaseUrl}${apiVersion === '' ? '' : `/${apiVersion}`}`;

    return {
        ssoAuthority,
        ssoClientId,
        ssoScope,

        apiBaseUrl,
        apiVersion,
        sawidayApiUrl
    };
};
