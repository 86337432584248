import './assets/styles.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';

import App from './App.vue';

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

import { FontAwesomeIcon, SwPreset } from '@relinks/sw-components';
import router from './router';
import { i18n } from './i18n';
const app = createApp(App);

app.use(PrimeVue, {
    ripple: true,
    unstyled: true,
    pt: SwPreset,
    ptOptions: {
        mergeProps: true
    }
});

app.use(createPinia());
app.use(router);
app.use(i18n);
app.use(ToastService);
app.use(ConfirmationService);

app.component('FontAwesomeIcon', FontAwesomeIcon);

Sentry.init({
    app,
    dsn: 'https://7c717782773db10650de1769b6b8a5d2@o405442.ingest.us.sentry.io/4507668490485760'
});

app.mount('#app');
