import { getRuntimeConfig } from '@/helpers/application/getRuntimeConfig';
import { useAuthStore } from '@/stores/auth';
import { useUserStore } from '@/stores/user';
import axios, { AxiosError } from 'axios';

interface ConfigParams {
    site: number | null;
    siteViewId: number | null;
    filterSiteId?: number;
    filterSiteViewId?: number;
}

const { apiBaseUrl } = getRuntimeConfig();

const adminApi = axios.create({
    baseURL: apiBaseUrl,
    headers: {
        'Content-Type': 'application/json'
    }
});

adminApi.interceptors.request.use(
    (config) => {
        const authStore = useAuthStore();
        const userStore = useUserStore();

        config.headers.Authorization = `Bearer ${authStore.accessToken}`;

        let configParams: ConfigParams = {
            site: null,
            siteViewId: null
        };

        if (config.params) {
            configParams = config.params;
        }

        configParams.site = userStore.defaultSiteId;
        configParams.siteViewId = userStore.defaultSiteviewId;

        config.params = configParams;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

adminApi.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger

        return response;
    },
    (error: AxiosError) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger

        return Promise.reject(error);
    }
);

export default adminApi;
