import { defineStore, acceptHMRUpdate } from 'pinia';
import type { ToastMessageOptions } from 'primevue/toast';
import { useToast } from 'primevue/usetoast';

export const useToastStore = defineStore('toast', () => {
    const toast = useToast();
    function addToast(
        severity: ToastMessageOptions['severity'] = 'info',
        summary: ToastMessageOptions['summary'] = '',
        detail: ToastMessageOptions['detail'] = '',
        life: ToastMessageOptions['life'] = 0
    ) {
        toast.add({
            severity,
            summary: summary,
            detail: detail,
            life
        });
    }
    return { addToast };
});
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useToastStore, import.meta.hot));
}
