<script setup lang="ts">
import { usePalletScansStore } from '@/stores/pallet-scans';

import { SwButton, SwCard, SwContainer, SwLoader } from '@relinks/sw-components';

import { useConfirm } from 'primevue/useconfirm';

import formatDate from '@/helpers/dates/formatDate';
import { type Ref, ref } from 'vue';
import { onMounted } from 'vue';
import { watch } from 'vue';
import { onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const inputField: Ref<HTMLInputElement | undefined> = ref();
const palletScanStore = usePalletScansStore();
const confirm = useConfirm();

const confirmDelete = (id: number) => {
    confirm.require({
        header: t('confirmDeleteModal.header'),
        message: t('confirmDeleteModal.message'),
        accept: () => palletScanStore.deletePalletScan(id),
        acceptProps: { severity: 'danger', label: t('terms.delete') },
        rejectProps: { severity: 'secondary', label: t('terms.cancel') }
    });
};

const setFocusToInput = () => {
    if (inputField.value) {
        inputField.value.focus();
    }
};

watch(
    () => palletScanStore.barcode,
    () => {
        if (palletScanStore.barcode.charAt(0) === '*' && palletScanStore.barcode.slice(-1) === '$') {
            palletScanStore.postPalletScan();
        }
    }
);

const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Control' || event.key === 'j') {
        event.preventDefault();
    }
};

onMounted(() => {
    setFocusToInput();

    document.addEventListener('keydown', handleKeyDown);
});

onUnmounted(() => {
    document.removeEventListener('keydown', handleKeyDown);
});

palletScanStore.fetchPalletScans({
    filters: {},
    pagination: { limit: 10, sorting: { field: 'createdAt', order: 'DESC' } }
});
</script>

<template>
    <SwContainer class="lg:py-4">
        <div class="sr-only">
            <input
                ref="inputField"
                v-model="palletScanStore.barcode"
                @keydown.enter="palletScanStore.postPalletScan"
                @blur="setFocusToInput"
            />
        </div>
        <SwLoader v-if="palletScanStore.loadingScans" block-loader class="my-0 grow" />

        <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8">
            <SwCard v-for="scan in palletScanStore.palletScans" :key="scan.barcode">
                <template #header>
                    <div class="bg-primary text-white text-center p-4 text-xl">
                        <h3>{{ scan.barcode }}</h3>
                    </div>
                </template>
                <template #content>
                    <div class="flex justify-between gap-4 items-center">
                        <div v-if="!scan.loading">
                            <p>{{ formatDate(scan.createdAt) }}</p>
                            <p>{{ $t('terms.by') }} {{ scan.user?.firstName }} {{ scan.user?.lastName }}</p>
                        </div>

                        <SwLoader v-else size="small" block-loader class="my-0 grow" />

                        <div>
                            <SwButton
                                :label="$t('terms.delete')"
                                severity="danger"
                                icon="trash"
                                :loading="scan.deleting"
                                @click="confirmDelete(scan.id)"
                            />
                        </div>
                    </div>
                </template>
            </SwCard>
        </div>
    </SwContainer>
</template>
