import { createRouter, createWebHistory } from 'vue-router';
import PalletScan from '@/views/PalletScan.vue';
import authenticationMiddleware from '@/router/middleware/authentication';
import permissionsMiddleware from '@/router/middleware/permissions';

declare module 'vue-router' {
    interface RouteMeta {
        authPage?: boolean;
        breadcrumbs?: string[] | boolean;
    }
}

import auth from '@/router/routes/auth';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior() {
        return { top: 0 };
    },
    routes: [
        ...auth,
        {
            path: '/',
            name: 'pallet-scan',
            component: PalletScan
        }
    ]
});

router.beforeEach(authenticationMiddleware);
router.beforeEach(permissionsMiddleware);

export default router;
