import { defineStore, acceptHMRUpdate } from 'pinia';
import { computed, ref, type ComputedRef, type Ref } from 'vue';

interface Address {
    country: string;
    locality: string;
    postal_code: string;
    street_address: string;
}

interface UserProfile {
    address: Address;
    email: string;
    email_verified: boolean;
    family_name: string;
    finance_departement: string;
    given_name: string;
    locale: string;
    name: string;
    preferred_username: string;
    profiles: string[];
    sites: string[];
}

interface AuthStore {
    accessToken: Ref<string>;
    profile: Ref<UserProfile | undefined>;
    isLoggedIn: ComputedRef<boolean>;
}

export const useAuthStore = defineStore<string, AuthStore>('auth', () => {
    const accessToken = ref('');
    const profile = ref<UserProfile | undefined>(undefined);

    const isLoggedIn = computed(() => Boolean(profile));

    return {
        accessToken,
        profile,
        isLoggedIn
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
