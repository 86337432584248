import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref, type Ref } from 'vue';

interface LoadingStore {
    appLoaded: Ref<boolean>;
    loadingProgress: Ref<number>;
    loadingStep: Ref<string>;
    redirectUrl: Ref<{
        path: string;
        query: string;
    }>;
}

export const useLoadingStore = defineStore<string, LoadingStore>('loading', () => {
    const appLoaded = ref(false);
    const loadingProgress = ref(0);
    const loadingStep = ref('');
    const redirectUrl = ref({
        path: '/',
        query: ''
    });

    return {
        appLoaded,
        loadingProgress,
        loadingStep,
        redirectUrl
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useLoadingStore, import.meta.hot));
}
