import dayjs from 'dayjs';
import nl from 'dayjs/locale/nl';

dayjs.locale(nl);

const formatDate = (value: string | undefined, format = 'DD-MM-YYYY HH:mm') => {
    if (!value) {
        return '';
    }

    const date = dayjs(value);

    return date.format(format);
};
export default formatDate;
