import { computed, ref, type ComputedRef, type Ref } from 'vue';
import adminApi from '@/services/admin-api';
import { defineStore, acceptHMRUpdate } from 'pinia';
import * as Sentry from '@sentry/vue';
import { locale } from '@/i18n';

interface Site {
    name: string;
    id: number;
    countryId: number;
    shortCode: string;
}

interface Siteview {
    name: string;
    id: number;
    siteId: number;
    root: string;
    displayName: string;
}

interface UserLanguage {
    value: string;
    settingValueId: number | null;
}

interface UserSettings {
    userId: number | null;
    defaultSiteId: number | null;
    defaultSiteviewId: number | null;
    storeId: number | null;
    financeDepartementId: number | null;
    pickupLocationId: number | null;
    username: string;
    email: string;
    fullName: string;
    firstName: string;
    lastName: string;
    sites: Site[];
    siteViews: Siteview[];
    profileIds: number[];
    filters: {};
    language?: UserLanguage;
}

export interface UserPreference {
    key: string;
    value: string | boolean;
    values?: string[];
    type: string;
    disabled: boolean;
    settingId?: number;
    settingValueId?: number;
}

interface UserStore {
    loading: Ref<boolean>;
    settings: Ref<UserSettings>;
    userPreferences: Ref<UserPreference[]>;
    defaultSiteId: ComputedRef<number | null>;
    defaultSiteviewId: ComputedRef<number | null>;
    userLanguage: ComputedRef<string>;
    fetchUser: () => Promise<void>;
}

export const useUserStore = defineStore<string, UserStore>('user', () => {
    const loading = ref(false);

    const settings = ref({
        userId: null,
        defaultSiteId: null,
        defaultSiteviewId: null,
        storeId: null,
        financeDepartementId: null,
        pickupLocationId: null,
        username: '',
        email: '',
        fullName: '',
        firstName: '',
        lastName: '',
        sites: [],
        siteViews: [],
        profileIds: [],
        filters: {},
        language: {
            value: '',
            settingValueId: -1
        }
    });

    const userPreferences = ref([
        {
            key: 'language',
            value: locale,
            values: ['nl-NL', 'en-US', 'fr-FR'],
            type: 'SelectButton',
            disabled: false,
            settingId: 78,
            settingValueId: computed(() => settings.value.language.settingValueId)
        },
        {
            key: 'startPage',
            value: 'tasks',
            values: ['tasks', 'orders', 'purchaseorders', 'quotes', 'shipments', 'products'],
            type: 'SelectButton',
            disabled: true
        },
        {
            key: 'taskDeadline',
            value: 'today',
            values: ['today', 'tomorrow'],
            type: 'SelectButton',
            disabled: true
        },
        {
            key: 'searchCommandPalette',
            value: false,
            type: 'ToggleSwitch',
            disabled: true
        }
    ]);

    const fetchUser = async () => {
        loading.value = true;

        try {
            const { data } = await adminApi.get('/me');
            if (data.success === true) {
                settings.value = data.data;
            }
        } catch (error) {
            Sentry.captureException(error);
        } finally {
            loading.value = false;
        }
    };

    const defaultSiteId = computed(() => settings.value.defaultSiteId);

    const defaultSiteviewId = computed(() => settings.value.defaultSiteviewId);

    const userLanguage = computed(() => settings.value.language?.value);

    return {
        loading,
        settings,
        userPreferences,
        defaultSiteId,
        defaultSiteviewId,
        userLanguage,
        fetchUser
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
