import sawidayClient from '@/services/apollo';
import type { GraphQLApiResultPromise } from '@/types/graphql';
import type { DocumentNode } from 'graphql';

const getValueByPath = (obj: any, path: string[]): any => {
    return path.reduce((acc, key) => acc && acc[key], obj);
};

const extractPayload = (response: any, responseKey?: string | string[]): any => {
    if (Array.isArray(responseKey)) {
        return getValueByPath(response.data, responseKey);
    } else if (typeof responseKey === 'string') {
        return response.data[responseKey];
    } else {
        return response.data;
    }
};

export const query = async <T>(
    query: DocumentNode,
    variables?: { [key: string]: string | number | {} },
    responseKey?: string | string[]
): GraphQLApiResultPromise<T> => {
    try {
        const response = await sawidayClient.query({
            query: query,
            variables: variables,
            fetchPolicy: 'no-cache'
        });

        return {
            status: 'success',
            payload: extractPayload(response, responseKey)
        };
    } catch (error) {
        return {
            status: 'error',
            payload: error
        };
    }
};

export const mutate = async <T>(
    query: DocumentNode,
    variables: any,
    responseKey?: string | string[]
): GraphQLApiResultPromise<T> => {
    try {
        const response = await sawidayClient.mutate({
            mutation: query,
            variables: variables,
            fetchPolicy: 'no-cache'
        });

        return {
            status: 'success',
            payload: extractPayload(response, responseKey)
        };
    } catch (error) {
        return {
            status: 'error',
            payload: error
        };
    }
};
